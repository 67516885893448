//Sub Heading Mixin
@mixin subheading-typography($color: '') {
  font-family: $font-family-patron-black;
  line-height: $line-height-medium;
  color: $color;
}
//heading Mixin
@mixin h1-heading {
  font-family: $font-family-patron-black;
  font-size: $h1-font-size;
  line-height: $line-height-normal;
  color: $black;
  @include media-breakpoint-down(lg) {
    font-size: $h2-font-size;
  }
  @include media-breakpoint-down(sm) {
    font-size: $h3-font-size;
  }
}

@mixin h2-heading {
  @include subheading-typography;
  font-size: $h2-font-size;
  @include media-breakpoint-down(sm) {
    font-size: $h4-font-size;
  }
}

@mixin h3-heading {
  @include subheading-typography;
  font-size: $h3-font-size;
}

@mixin h4-heading {
  @include subheading-typography;
  font-size: $h4-font-size;
  @include media-breakpoint-down(sm) {
    font-size: $h5-font-size;
  }
}

@mixin h5-heading {
  @include subheading-typography;
  font-size: $h5-font-size;
  @include media-breakpoint-down(sm) {
    font-size: $h6-font-size;
  }
}
@mixin heading-small {
  @include subheading-typography;
  font-size: $font-size-md;
  @include media-breakpoint-down(sm) {
    font-size: $font-size-md;
  }
}

@mixin h6-heading {
  font-family: $font-family-patron-regular;
  font-size: $font-size-base;
  line-height: $line-height-medium;
  letter-spacing: $character-spacing-1;
}
//Body text Mixin
@mixin bodyLargeBold {
  font-family: $font-family-notosans-bold;
  font-size: $font-size-md;
  line-height: $line-height-big;
  letter-spacing: $character-spacing-0-2;
  @include media-breakpoint-down(sm) {
    font-size: $font-size-base;
  }
}

@mixin bodyLargeRegular {
  font-family: $font-family-notosans-regular;
  font-size: $font-size-md;
  line-height: $line-height-big;
  letter-spacing: $character-spacing-0-2;
  @include media-breakpoint-down(lg) {
    font-size: $font-size-base;
  }
  @include media-breakpoint-down(sm) {
    font-size: $font-size-base;
  }
}

@mixin bodyMediumBold {
  font-family: $font-family-notosans-bold;
  font-size: $font-size-base;
  line-height: $line-height-big;
}
@mixin bodyMediumRegular {
  font-family: $font-family-notosans-regular;
  font-size: $font-size-base;
  line-height: $line-height-big;
  @include media-breakpoint-down(sm) {
    font-size: $font-size-xs;
  }
}
@mixin bodysmallBold {
  font-family: $font-family-notosans-bold;
  font-size: $font-size-xs;
  line-height: $line-height-big;
}
@mixin bodysmallRegular {
  font-family: $font-family-notosans-regular;
  font-size: $font-size-xs;
  line-height: $line-height-big;
}
//Label text Mixin
@mixin labelMedium {
  font-family: $font-family-patron-medium;
  line-height: $line-height-medium;
  font-size: $font-size-base;
}

@mixin labelSmall {
  font-family: $font-family-patron-medium;
  line-height: $line-height-medium;
  font-size: $font-size-xs;
}

//Button Mixin
@mixin btnPrimary {
  background-color: $white;
  padding: $btn-padding-y $btn-padding-x;
  height: rem(56);
  border-radius: $btn-radius;
  font-family: $font-family-patron-medium;
  // font-size: $font-size-base;
  font-weight: 500;
  font-size: 16px;
  line-height: 19.2px;
  // line-height: $line-height-medium;
  color: $purple-text;
  border: rem(0);
  transition: all ease-in-out 0.5s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  outline: rem(1) solid transparent;

  &:before {
    position: absolute;
    left: -110%;
    top: rem(-10);
    z-index: -1;
    width: 220%;
    height: rem(76);
    aspect-ratio: 1;
    background-color: $purple-text;
    border-radius: 3.125rem;
    transform: scale(0);
    transition: transform 0.3s ease-in-out;
    content: '';
  }
  &:active:before,
  &:hover:before {
    transform: scale(1);
  }
  &:before {
    background-color: $off-white;
  }
  &:hover,
  &:focus,
  &:focus-within,
  &:active,
  &:disabled,
  &:focus-visible,
  &:not(.btn-check) + .btn:active {
    box-shadow: 0 0 0 0.5625rem rgba(120, 104, 134, 0.3);
    outline: rem(1) solid $off-white;
    transition-delay: 0s;
    background: $off-white !important;
    color: $purple-text !important;
    box-shadow: none !important;
    border: rem(0) !important;
  }
}

@mixin btnSecondary {
  background-color: $purple;
  padding: $btn-padding-y $btn-padding-x;
  height: rem(56);
  border-radius: $btn-radius;
  font-family: $font-family-patron-regular;
  font-size: $font-size-base;
  line-height: $line-height-medium;
  color: $white;
  border: rem(0);
  transition: all ease-in-out 0.5s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  outline: rem(1) solid transparent;


  &:before {
    position: absolute;
    left: -110%;
    top: rem(-10);
    z-index: -1;
    width: 220%;
    height: rem(76);
    aspect-ratio: 1;
    background-color:$purple-text;
    border-radius: 3.125rem;
    transform: scale(0);
    transition: transform 0.3s ease-in-out;
    content: '';
  }
  &:active:before,
  &:hover:before {
    transform: scale(1);
  }

  &:hover,
  &:focus,
  &:focus-within,
  &:active,
  &:focus-visible,
  &:not(.btn-check) + .btn:active {
    background-color: $purple-text !important;
    color: $white !important;
    transition-delay: 0s;
    box-shadow: none !important;
    border: rem(0) !important;
  }
}

@mixin btnSecondaryOutline {
  background-color: transparent;
  padding: $btn-padding-y $btn-padding-x;
  height: rem(56);
  border-radius: $btn-radius;
  font-family: $font-family-patron-regular;
  font-size: $font-size-base;
  line-height: $line-height-medium;
  color: $purple;
  border: rem(0);
  transition: all ease-in-out 0.5s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  outline: rem(1) solid transparent;
  border: 2px solid $purple;


  &:before {
    position: absolute;
    left: -110%;
    top: rem(-10);
    z-index: -1;
    width: 220%;
    height: rem(76);
    aspect-ratio: 1;
    background-color:$purple-text;
    border-radius: 3.125rem;
    transform: scale(0);
    transition: transform 0.3s ease-in-out;
    content: '';
    border: 2px solid $purple;
  }
  &:active:before,
  &:hover:before {
    transform: scale(1);
  }

  &:hover,
  &:focus,
  &:focus-within,
  &:active,
  &:focus-visible,
  &:not(.btn-check) + .btn:active {
    background-color: $purple-text !important;
    color: $white !important;
    transition-delay: 0s;
    box-shadow: none !important;
    border: 2px solid $purple !important;
  }
}

@mixin btnDisabled {
  background-color: $disabled !important;
  padding: $btn-padding-y $btn-padding-x;
  height: rem(56);
  border-radius: $btn-radius;
  font-family: $font-family-patron-regular;
  font-size: $font-size-base;
  line-height: $line-height-medium;
  color: $grey !important;
  border: rem(0);
  transition: all ease-in-out 0.5s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  outline: rem(1) solid transparent;
}

//Input Field Mixin
@mixin inputTypo {
  padding: $input-padding-y $input-padding-x;
  border-radius: $input-border-radius;
  background-color: $input-bg;
  height: rem(56);
  border-color: transparent;
  //input typography
  font-family: $font-family-notosans-regular;
  font-size: $font-size-base;
  color: $purple-text;
  letter-spacing: $character-spacing-0-16;
  line-height: $line-height-big;
  @include media-breakpoint-down(sm) {
    font-size: $font-size-base;
  }

  &:focus {
    box-shadow: none;
    border-color: transparent;
  }
  &.is-invalid {
    border-color: $error;
    padding-right: $input-padding-x;
    color: $error;
    background-image: none;
    &:focus {
      box-shadow: none;
    }
  }
}

//Select Field Mixin
@mixin selectTypo {
  height: rem(56);
  padding: rem(8) rem(50) rem(8) rem(16);
  background: $white url("~/public/assets/images/select-dropdown-arw.svg") no-repeat;
  background-size: 18px 11px;
  background-position: calc(100% - 20px) 23px;
}

// Modal mixin
@mixin modalMixin {
  box-shadow: rem(0) rem(5) rem(65) rgba(0, 0, 0, 0.09);
  border-radius: $modal-border-radius;
  padding: $modal-padding-y $modal-padding-x;
  .btn-close {
    width: rem(14);
    height: rem(14);
    font-size: $font-size-xs;
  }
  @include media-breakpoint-down(sm) {
    padding: rem(24);
  }
}
