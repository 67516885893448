@font-face {
  font-family: 'NotoSans-Bold';
  src: url('./fonts/NotoSans-Bold.eot');
  src: url('./fonts/NotoSans-Bold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/NotoSans-Bold.woff2') format('woff2'),
    url('./fonts/NotoSans-Bold.woff') format('woff'),
    url('./fonts/NotoSans-Bold.ttf') format('truetype'),
    url('./fonts/NotoSans-Bold.svg#NotoSans-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NotoSans-Regular';
  src: url('./fonts/NotoSans-Regular.eot');
  src: url('./fonts/NotoSans-Regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/NotoSans-Regular.woff2') format('woff2'),
    url('./fonts/NotoSans-Regular.woff') format('woff'),
    url('./fonts/NotoSans-Regular.ttf') format('truetype'),
    url('./fonts/NotoSans-Regular.svg#NotoSans-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Patron-Black';
  src: url('./fonts/Patron-Black.eot');
  src: url('./fonts/Patron-Black.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Patron-Black.woff2') format('woff2'),
    url('./fonts/Patron-Black.woff') format('woff'),
    url('./fonts/Patron-Black.ttf') format('truetype'),
    url('./fonts/Patron-Black.svg#Patron-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Patron-Medium';
  src: url('./fonts/Patron-Medium.eot');
  src: url('./fonts/Patron-Medium.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Patron-Medium.woff2') format('woff2'),
    url('./fonts/Patron-Medium.woff') format('woff'),
    url('./fonts/Patron-Medium.ttf') format('truetype'),
    url('./fonts/Patron-Medium.svg#Patron-Medium') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Patron-Regular';
  src: url('./fonts/Patron-Regular.eot');
  src: url('./fonts/Patron-Regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Patron-Regular.woff2') format('woff2'),
    url('./fonts/Patron-Regular.woff') format('woff'),
    url('./fonts/Patron-Regular.ttf') format('truetype'),
    url('./fonts/Patron-Regular.svg#Patron-Regular') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

a {
  transition: all 0.3s ease-in-out;
  color: $white;
  cursor: pointer;
  &:hover,
  &:active,
  &:focus {
    // color: $onyx-dark !important;
    color: $link-hover !important;
  }
}
.container {
  @include media-breakpoint-down(xl) {
    --bs-gutter-x: 2rem;
  }
  @include media-breakpoint-down(lg) {
    --bs-gutter-x: 3rem;
  }
  @include media-breakpoint-down(sm) {
    --bs-gutter-x: 3rem;
  }
}
//  Heading
h1 {
  @include h1-heading;
}
h2 {
  @include h2-heading;
}
h3 {
  @include h3-heading;
}
h4 {
  @include h4-heading;
}
h5 {
  @include h5-heading;
}
h6 {
  @include h6-heading;
}
.heading-small {
  @include heading-small;
}

.custom-heading-h2 {
  @include h4-heading;
  color: $purple-text;
}
.menu-button-text {
  @include h6-heading;
}
.custom-subheading {
  @include h6-heading;
}
.custom-data-text {
  @include h2-heading;
}
.custom-tariff-name{
  @include h3-heading;
}
.custom-price-text {
  @include h4-heading;
}

// Body Text Scss i.e: <p> <label> etc.
.body-large-bold {
  @include bodyLargeBold;
}
.body-large-regular {
  @include bodyLargeRegular;
}
.body-medium-bold {
  @include bodyMediumBold;
}
.body-medium-regular {
  @include bodyMediumRegular;
}
.body-small-bold {
  @include bodysmallBold;
}
.body-small-regular {
  @include bodysmallRegular;
}
// Label Text Scss i.e: <p> <label> etc.
.label-medium {
  @include labelMedium;
}
.label-small {
  @include labelSmall;
}
.box-radius {
  border-radius: $box-radius;
}
// Input scss
.form-control {
  &::-webkit-input-placeholder {
    color: $grey;
    opacity: 0.5;
  }
  &::-moz-placeholder {
    color: $grey;
    opacity: 0.5;
  }
  &:-ms-input-placeholder {
    color: $grey;
    opacity: 0.5;
  }
  &:-moz-placeholder {
    color: $grey;
    opacity: 0.5;
  }
  @include inputTypo;
  & ~ .invalid-feedback {
    color: $error;
  }
  &.with-icon {
    padding-right: 60px;
  }
}
.invalid-feedback {
  color: $error;
}
.input-group {
  .input-group-text {
    height: rem(54);
    margin-top: rem(1);
  }
  .is-invalid ~ .input-group-text {
    border-color: $error;
    height: rem(56);
    margin-top: rem(0);
  }
}
// Select Scss
.form-select {
  @include selectTypo;
}

// Radio Scss
.form-check {
  &.radio-check {
    .form-check-input {
      width: $form-check-input-width;
      height: $form-check-min-height;
      &:checked {
        outline: rem(1) solid #333;
        background-image: url('~/public/assets/images/check-icon-purple.svg');
        background-color: $white;
        // border-color: $purple;
        width: rem(20);
        height: rem(20);
        border: rem(4) solid $white;
      }
      &:focus {
        box-shadow: none;
      }
    }
    &.dark-radio {
      .form-check-input {
        outline: none;
        &:checked {
          background-color: $white;
          // border-color: $purple;
          outline: none;
        }
      }
    }
  }
}
// Switch Button Scss
.form-switch {
  .form-check-label {
    cursor: pointer;
    width: calc(100% - rem(80));
  }
  .form-check-input {
    width: rem(56);
    cursor: pointer;
    height: rem(32);
    background-color: $disabled;
    background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27><circle r=%273%27 fill='white'/></svg>");
    border: 0;
    margin: 0;
    &:focus {
      background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27><circle r=%273%27 fill='white'/></svg>");
    }
    &:checked {
      background-color: $purple;
    }
  }
  &.dark-switch {
    .form-check-input {
      background-color: $purple-light;
      &:checked {
        background-color: $mango;
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .pt-10 {
    padding-top: 6rem !important;
  }
  .pb-20 {
    padding-bottom: 12rem !important;
  }
}

// Button Scss
.btn-primary {
  @include btnPrimary;
}
.btn-secondary {
  @include btnSecondary;
}
.btn-disabled {
  @include btnDisabled;
}
.btn-outline-secondary {
  @include btnSecondaryOutline;
}

.vvi-btn {
  border-radius: $btn-radius;
  color: $purple-text !important;
  height: rem(56);
  background-color: $white;
  font-family: $font-family-patron-medium;
  font-weight: 500;
  font-size: 16px;
  line-height: 19.2px;
  color: $purple-text;
  border: rem(0);
  &:hover {
    // color: $white !important;
    background-color: $white !important;
  }
//   &:hover,
//   &:focus,
//   &:focus-within,
//   &:active,
//   &:disabled,
//   &:focus-visible,
//   &:not(.btn-check) + .btn:active {
//     // background: transparent !important;
//     // text-decoration: none !important;
//     // background: $white !important;
//     // &:hover,
//     // &:active {
//     background: $white !important;
    
//     // }
//   }
}

.vvi-btn-link:hover {
    background-color: $white !important;
    color: $purple-text !important;
}
  
// Modal Scss
.share-modal {
  .modal-dialog {
    max-width: rem(600);
    .modal-content {
      @include modalMixin;
    }
  }
}

.modal {
  background: #261d2ea6;
}

//Category link Scss
.category-link {
  li {
    margin-bottom: rem(8);
    a {
      background-color: $white;
      border-radius: $box-radius;
      padding: $input-padding-y $input-padding-x;
      text-decoration: none;
      &:hover {
        color: $purple-text;
      }
    }
  }
}

// Alert belt Scss
.share-alert-success {
  padding-top: $alert-padding-y;
  padding-bottom: $alert-padding-y;
  border-radius: $input-border-radius;
}

// Accordion Arrow Scss
.accordion {
  .accordion-button {
    &:after {
      display: none;
    }
    .fa-angle-down {
      display: none;
    }
    &.collapsed {
      .fa-angle-up {
        display: none;
      }
      .fa-angle-down {
        display: block;
      }
    }
  }
}

// Date Picker
.react-datepicker {
  border: none !important;
  box-shadow: 0px -6px 8px 5px rgba(0, 0, 0, 0.2), 0px 13px 20px 10px rgba(0, 0, 0, 0.19);
  font-family: 'NotoSans-Regular';
  font-size: 1rem;
}
.react-datepicker__header {
  color: white !important;
  background-color: #534164 !important;
  padding: 5px 0;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header,
.react-datepicker__day-name,
.react-datepicker__time-name {
  color: white !important;
}

.react-datepicker__day {
  color: #534164 !important;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  color: white !important;
  background-color: #534164 !important;
}

.react-datepicker__navigation {
  // top: 8px;
  display: none !important;
}

.react-datepicker__day--disabled {
  color: #534164 !important;
  opacity: 0.5;
}

.dob-div {
  .invalid-feedback {
    display: block !important;
  }

  .was-validated .form-control:valid,
  .form-control.is-valid {
    border-color: var(--bs-form-valid-border-color);
    padding-right: calc(1.5em + 0.75rem);
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%23198754%27 d=%27M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e');
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
}

// .react-datepicker__month-select,
// .react-datepicker__year-select {
//   background: #534164;
//   color: white;
//   border: none;
// }
select {
  .default-option,
  ::placeholder,
  &.show-placeholder,
  &::-webkit-input-placeholder {
    // color: rgba(255, 255, 255, 0.5);
    color: rgba(98, 107, 111, 0.5);
    // color: #b5b5b5;
    // opacity: 0.5;
  }
  option {
    color: #534164;
      font-family: $font-family-notosans-regular !important;
  }
  &.react-datepicker__month-select,
  &.react-datepicker__year-select {
    background: #534164;
    color: white;
    border: none;
      // font-family: $font-family-notosans-regular !important;
      // font-family: inherit !important;
      option {
      color: white;
      // font-family: inherit !important;
      font-family: $font-family-notosans-regular !important;
      // font: -moz-pull-down-menu;
      // -moz-appearance: inherit;
    }
  }
}
.react-datepicker__current-month {
  display: none !important;
}
.react-datepicker__input-container {
  // width: 80%;
  // padding: 0 50px 0 16px;
  border-radius: $box-radius;
  height: rem(56);
  background: $white url('~/public/assets/images/select-dropdown-arw.svg') no-repeat;
  background-size: 18px 11px;
  background-position: calc(100% - 20px) 23px;
  input {
    border: none;
    width: 100px;
    // background-color: transparent !important;
    // color: #534164;
    // padding: 0 -30px 0 -15px;
    border: 0;
    width: 100% !important;
    padding: 17px 16px;
    border-radius: 8px !important;
    background-color: var(--bs-body-bg);
    height: 3.5rem;
    border-color: transparent;
    font-family: 'NotoSans-Regular';
    font-size: 1rem;
    color: #503a64;
    letter-spacing: -0.16px;
    line-height: 140%;
    background: $white url('~/public/assets/images/select-dropdown-arw.svg') no-repeat;
    background-size: 18px 11px;
    background-position: calc(100% - 20px) 23px;
    &::placeholder,
    &::-webkit-input-placeholder {
      // color: rgba(255, 255, 255, 0.2);
      color: rgba(98, 107, 111, 0.5);
      // color: #b5b5b5;
    }
    &:focus {
      outline: none;
    }
  }

  // input:-webkit-autofill {
  //   display: none !important;
  // }

  // input:-webkit-autofill:focus {
  //   display: none !important;
  // }
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  // -webkit-box-shadow: 0 0 0 30px white inset !important;
  background-color: transparent !important;
}

.react-datepicker__header__dropdown {
  margin: 5px 0 !important;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
  // border: none;
  border-bottom-color: #534164;
}

.react-datepicker-popper {
  z-index: 5 !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.text-white-hover {
  &:hover {
    color: $white !important;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    background-color: transparent;
  }
}
