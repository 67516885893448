// @import '@theme/variables.scss';
@import "~bootstrap/scss/mixins/breakpoints";
@import '../../../theme/function.scss';
@import '../../../theme/variables.scss';
@import '../../../theme/mixins.scss';
@import '../../../theme/typography.scss';

.grey-shade {
    &::before {
        @include media-breakpoint-down(sm) {
            background: url('~/public/assets/images/gray-curve-shade-2-m.svg') center no-repeat !important;
            background-size: cover !important;
            height: rem(60) !important;
            top: rem(-55) !important;
          }
    }
}