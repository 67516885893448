@import '~bootstrap/scss/functions', '~bootstrap/scss/variables';
@import '../../../../../../node_modules/bootstrap/scss/mixins/breakpoints';

@import '../../../../../theme/theme.scss';

.overview-tariff {
  .list-group-item {
    background-image: url('~/public/assets/images/check-icon.svg');
    background-repeat: no-repeat;
    background-position: left rem(7);
    padding-left: rem(20);
    span {
      sup {
        .star-btn {
          margin-top: -10px;
        }
      }
    }
  }
}