@import '~bootstrap/scss/mixins/breakpoints';
@import '../../../theme/function.scss';
@import '../../../theme/variables.scss';
@import '../../../theme/mixins.scss';
@import '../../../theme/typography.scss';

.frequently-asked-section {
  .orange-gray-bubble {
    &:before {
      left: rem(-60);
      top: rem(330);
    }
  }
  h1 {
    @include media-breakpoint-down(sm) {
      font-size: $h3-font-size;
    }
  }
  .accordion {
    .accordion-item {
      .body-large-bold {
        @include media-breakpoint-down(sm) {
          font-size: rem(20);
        }
      }
    }
  }
}
.questions-clarified-section {
  &.purple-curve-shade-2:after {
    @include media-breakpoint-down(lg) {
      bottom: rem(-65);
      height: rem(68);
    }
  }
}
.frequently-topic-section {
  &.gray-shade-3 {
    &:before {
      @include media-breakpoint-down(sm) {
        background: url('~/public/assets/images/gray-curve-shade-4-m.svg') no-repeat !important;
        background-size: 100% !important;
        height: rem(100) !important;
        top: rem(-77) !important;
      }
    }
  }
}
.questions-clarified-section {
  margin-bottom: 150px;
  @include media-breakpoint-down(md) {
    margin-bottom: 50px;
  }
  &.friends-purple-shade-2 {
    &:before {
      @include media-breakpoint-down(sm) {
        background: url('~/public/assets/images/friends-purple-curve-shade-4-mobile.svg') no-repeat;
        background-size: cover;
        height: rem(77);
        top: rem(-77);
      }
    }
    &:after {
      @include media-breakpoint-down(sm) {
        background: url('~/public/assets/images/project-top-purple-curve-shade1-mobile.svg')
          no-repeat;
        background-size: 100%;
        height: rem(78);
      }
    }
  }
}
