@import "../../../../../node_modules/bootstrap/scss/mixins/breakpoints";
@import '../../../../theme/function.scss';
@import "../../../../theme/variables.scss";

.link-text {
    p {

        a {
            color: $purple;

            &:hover {
                color: $link_hover;
            }
        }
    }

}