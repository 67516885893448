@import "~bootstrap/scss/mixins/breakpoints";
@import '../../../../theme/function.scss';
@import '../../../../theme/variables.scss';
@import '../../../../theme/mixins.scss';
@import '../../../../theme/typography.scss';

/* Consent & cokkie Modal Popup css*/
.consent-modal {
	.form-switch {
		.form-check-label {
			cursor: pointer;
			width: calc(100% - rem(136));
			@include media-breakpoint-down(sm) {
				width: calc(100% - rem(120));
			}
			~ {
				.justify-content-center {
					width: rem(126);
					flex-direction: column;
    				align-items: center;
					margin-left: 10px;
					@include media-breakpoint-down(sm) {
						width: rem(110);
					}
				}
			}
		}
	}
}
.cokkie-cnt {
	overflow: auto;
	max-height: 220px;
}
.close-modal {
	color: $grey;
	i {
		font-size: rem(26);
	}
	&:hover {
		color: $link-hover !important;
	}
}
.back-modal {
	color: $grey;
	i {
		font-size: rem(26);
	}
	&:hover {
		color: $link-hover !important;
	}
}

.tariff-detail {
	p {
		margin-bottom: 0;
	}
}