.splash-screen-background {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .splash-screen-icon {
    img {
      width: 20vw;
      @media only screen and (max-width: 1200px) {
        width: 30vw;
      }
      @media only screen and (max-width: 768px) {
        width: 50vw;
      }

      @media only screen and (max-width: 567px) {
        width: 60vw;
      }
    }
  }
}
