@import '~bootstrap/scss/mixins/breakpoints';
@import '../../../theme/function.scss';
@import '../../../theme/variables.scss';
@import '../../../theme/mixins.scss';
@import '../../../theme/typography.scss';

.project-btm-banner .project-image-shade-1 {
  img {
    width: 100%;
    height: 100%;
    max-width: 1440px;
    max-height: 670px;
    gap: 10px;
    object-fit: cover;

    @include media-breakpoint-down(xxl) {
      // width: 1440px;
      height: 670px;
    }

    @include media-breakpoint-down(xl) {
      // width: 1440px;
      height: 550px;
    }

    @include media-breakpoint-down(lg) {
      // width: 1440px;
      height: 400px;
    }

    @include media-breakpoint-down(md) {
      // width: 1440px;
      height: 400px;
    }

    @include media-breakpoint-down(sm) {
      // width: 1440px;
      height: 375px;
    }
  }
}
