.swoosh-element {
  &.top {
    z-index: 0;
    width: 100%;
    // min-height: 120px;
    height: calc((120 / 375) * 100%);
    margin: -2px;
    scale: 1.05;
    // margin-top: -20px;
    // margin-bottom: -20px;

    @media only screen and (min-width: 0px) and (max-width: 992px) {
      // min-height: 70px;
      min-height: auto;
    }
  }

  &.container,
  &.container-fluid {
    z-index: 1;
  }

  &.bottom {
    z-index: 0;
    width: 100%;
    margin: -2px;
    // scale: 1.05;
    // min-height: 120px;
    height: calc((120 / 375) * 100%);
    // margin-top: -20px;
    // margin-bottom: -20px;

    @media only screen and (min-width: 0px) and (max-width: 992px) {
      // min-height: 70px;
      min-height: auto;
    }
  }
}
