@import '~bootstrap/scss/mixins/breakpoints';
@import '../../../theme/function.scss';
@import '../../../theme/variables.scss';
@import '../../../theme/mixins.scss';
@import '../../../theme/typography.scss';

.uber-share-section {
  &.friends-purple-shade-1 {
    &:after {
      background: url('~/public/assets/images/project-banner-curve-shade1.svg') center no-repeat;
      background-size: cover;
      height: rem(158);
      bottom: 0;
      @include media-breakpoint-down(xl) {
        height: rem(84);
      }
      @include media-breakpoint-down(md) {
        height: rem(64);
      }
      
      @include media-breakpoint-down(sm) {
        height: rem(96);
        background: url('~/public/assets/images/project-banner-curve-shade1-mobile.svg') no-repeat;
        background-size: cover;
      }
      @media (min-width: 500px) and (max-width: 576px) { 
        height: rem(120);
      }
    }
    &:before {
      background: url('~/public/assets/images/ueber-share-purple-curve.svg') no-repeat;
      background-size: cover;
      position: absolute;
      top: rem(-4);
      content: '';
      width: 100%;
      left: 0;
      height: rem(158);
      z-index: 1;
      @include media-breakpoint-down(xl) {
        height: rem(90);
      }
    }
  }
}
.uber-tarif-section {
  // &:before {
  //   background: url('~/public/assets/images/uber-share-white-curve.svg') no-repeat;
  //   background-size: cover;
  //   position: absolute;
  //   top: rem(-4);
  //   content: '';
  //   width: 100%;
  //   left: 0;
  //   /* height: rem(158); */
  //   height:25%;
  //   z-index: 1;
  //   @include media-breakpoint-down(xl) {
  //     height: rem(98);
  //   }
  //   @include media-breakpoint-down(lg) {
  //     height: rem(66);
  //   }
  //   // @include media-breakpoint-down(md) {
  //   //   // background: url('~/public/assets/images/white-curve-shade-1-m.svg') no-repeat;
  //   //   background-size: cover;
  //   //   height: rem(158);
  //   // }
  //   @include media-breakpoint-down(sm) {
  //     background: url('~/public/assets/images/white-curve-shade-1-m.svg') no-repeat;
  //     background-size: cover;
  //     height: rem(81);
  //     top: rem(-40);
  //   }
  //   @media (min-width: 520px) and (max-width: 576px) { 
  //     height: rem(96);
  //   }
  // }
  // &:after {
  //   background: url('~/public/assets/images/project-gray-curve-shade2.svg')  no-repeat;
  //   background-size:cover;
  //   bottom: 0;
  //   /* height: rem(160); */
  //   height:25%;
  //   position: absolute;
  //   content: '';
  //   width: 100%;
  //   z-index: 1;

  //   @include media-breakpoint-down(xxl) {
  //     height: rem(120);
  //   }
  //   @include media-breakpoint-down(xl) {
  //     height: rem(100);
  //   }
  //   @include media-breakpoint-down(lg) {
  //     height: rem(75);
  //   }
  //   @include media-breakpoint-down(md) {
  //     height: rem(65);
  //   }
  // }
}
// .question-row {
//   &.gray-shade-1 {
//     &:after {
//       bottom: rem(-156);
//       @include media-breakpoint-down(sm) {
//         background: url('~/public/assets/images/gray-curve-shade-1-m.svg') no-repeat;
//         background-size: cover;
//         height: rem(76);
//       }
//     }
//   }
// }
.question-row {
  .tariff-shadow-box {
    @include media-breakpoint-down(sm) {
      margin-top: rem(50);
    }
  }
  .project-share-formet-gray-shade-2{
    &::before {
      height: 160px;
      @include media-breakpoint-down(xxl) {
        height: rem(120);
      }
      @include media-breakpoint-down(xl) {
        height: rem(100);
      }
      @include media-breakpoint-down(lg) {
        height: rem(75);
      }
      @include media-breakpoint-down(md) {
        height: rem(65);
      }
    }
  }
  .mob-tariff {
    @include media-breakpoint-down(sm) {
      --bs-gutter-x: 2rem;
    }
    .rounded {
      @include media-breakpoint-down(sm) {
        margin-top: rem(-150);
      }
    }
  }
}
h2 {
  &.pink-color-text {
    @include media-breakpoint-down(lg) {
      font-size: $h3-font-size;
    }
  }
  &.primary-color-text {
    @include media-breakpoint-down(lg) {
      font-size: $h3-font-size;
    }
  }
}
.get-more-gb-row {
  &.friends-purple-shade-2 {
    &::before {
      @include media-breakpoint-down(sm) {
          height: rem(120);
          top: rem(-120);
          background-size: cover;
      }
    }
  }
}
.share-mobile-section {
  &.uber-tarif-section {
    /* height: rem(979); */
    height: calc(100vh - 5px) !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
   

    @include media-breakpoint-down(xl) {
      height: rem(734);
    }
    @include media-breakpoint-down(md) {
      background-position: center top !important;
    }
    @include media-breakpoint-down(sm) {
      height: 100% !important;  
    }
    // .rounded {
      // position: relative;
      // top: rem(80);
      // @include media-breakpoint-down(xl) {
      //   top: -30px;
      // }
    // }
  }
}

.top-image-share {
  left: 55px;
  bottom: -80px;
  @include media-breakpoint-down(xl) {
    bottom: -70px;
    left: 0;
  }
  @include media-breakpoint-down(lg) {
    bottom: -70px;
    left: 0;
  }
  @include media-breakpoint-down(md) {
    bottom: -60px;
    left: 0px;
  }
  @include media-breakpoint-down(sm) {
    bottom: -80px;
    left: 0;  
  }
}

.about-heading-text {
  // @include media-breakpoint-down(md) {
  //   margin-bottom: 150px;
  // }
  @media only screen and (min-width: 575.98px) and (max-width: 767.98px) {
    margin-bottom: rem(200);
  }
  @media only screen and (min-width: 0px) and (max-width: 575.98px) {
    margin-bottom: rem(200);
  }
}

.about-page-tariff {
  .tariff-shadow {
    box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.25);
    padding: 40px 22px;

    @include media-breakpoint-down(md) {
      margin-top: rem(-350);
    }

    @include media-breakpoint-down(sm) {
      margin-top: rem(50);
      padding: 28px 18px;
    }

    .tariff-image {
      top: -30px;
      position: absolute;
      left: 0;
      right: 0;
    }
  }
}
