@import "~bootstrap/scss/mixins/breakpoints";
@import '../../../../theme/function.scss';
@import '../../../../theme/variables.scss';
@import '../../../../theme/mixins.scss';
@import '../../../../theme/typography.scss';


footer {
    ul{
        li{
            a {
                &:hover {
                    color: $purple-text !important;
                }
                &:focus {
                    color: $purple-text !important;    
                }
            }
        }
    }
} 

.responsive-logo{
    max-width: rem(135);
    @include media-breakpoint-down(lg) {
        max-width: rem(150);
    }
    @include media-breakpoint-down(md) {
        max-width: rem(135);
    }
    @include media-breakpoint-down(sm) {
        max-width: rem(130);
    }
}

//commenting scss as it's getting conflict with new scss
// .text-off-white{
//     color: #EFECE8;
// }
// .text-purple {
//   color: #534164;
// }
// .text-pink {
//     color: #E999AB !important;
// }
// .custom-home-label {
//     padding-left: 30px;
// }
// h1 {
//     font-size: 50px !important;
// }

// input[type="radio"]
// {
//   appearance: none;
// }

// input[type="radio"] + label
// {
//   cursor: pointer;
//   position: relative;
//   padding-left: 30px;
//   line-height: 20px;
// }

// input[type="radio"] + label::before
// {
//   content: "";
//   display: inline-block;
//   width: 20px;
//   aspect-ratio: 1;
//   border: 2px solid #ffffff;
//   border-radius: 50%;
//   position: absolute;
//   top: 50%;
//   left: 0;
//   transform: translateY(-50%);
//   opacity: 1;
//   transition: all 0.3s;
// }

// input[type="radio"] + label::after
// {
//   content: "";
//   display: inline-block;
//   width: 20px;
//   aspect-ratio: 1;
//   border: 1px solid #ffffff;
//   background: #ffffff;
//   border-radius: 50%;
//   position: absolute;
//   left: 0px;
//   top: 50%;
//   transform: translateY(-50%);
//   opacity: 0;
//   transition: all 0.3s;
// }

// input[type="radio"]:checked + label::after
// {
//   opacity: 1;
// }
// .custom-white-button {
//   background-color: #fff !important;
//   padding: 10px 80px !important;
//   min-width: 250px;
// }
// .curved-img {
//   img {
//     width: 100%;
//   }
// }
// .gray-backg {
//   background-color: #EFECE8;
// }
// .service-card {
//   img {

//   }
// }
// .bottom-footer {
//   display: block;
//   a{
//     text-decoration: none;margin-right: 20px;
//     margin-top: 5px;
//   }
// }
// .banner-sec {
//   position: relative;
//   &::before{
//     content: "";
//     left: 90px;
//     background: url('./left-eliipse.png');
//     position: absolute;
//     background-repeat: no-repeat;
//     width: 80px;
//     height: 80px;
//     z-index: 9;
//     top:110px;
//   }
//   &::after{
//     content: "";
//     right:19%;
//     position: absolute;
//     background-repeat: no-repeat;
//     width: 46px;
//     height: 46px;
//     background-color: #F39E72;
//     z-index: 9;
//     border-radius: 50px;
//     top:150px;
//   }
//   // &::after{
//   //   content: "";
//   //   left: 0px;
//   //   background: url('./right-ellipse.png');
//   //   position: absolute;
//   //   background-repeat: no-repeat;
//   //   width: 100%;
//   //   height: 80%;
//   // }
// }

// .curved-img{
//   position: relative;
//   &::before{
//     content:url('./left-ellipse.png');
//     position: absolute;
//     top: 20px;
//     left: -120px;
//     right: 0;
//     margin: 0 auto;
//     text-align: center;

//   }
//   &::after{
//     content:url('./right-ellipse.png');

//     position: absolute;
//     left: auto;
//     top:30px;
//     right: 80px;
//   }
// }

// .home-a-link {
//   margin-top:35px;
//   p{
//     color: #fff;
//     display: flex;
//     margin: 0px;
//   }
//   a {
//     text-decoration: underline;
//     display: block;
//     margin-left: 10px;
//     margin-bottom: 5px;

//   }
// }

// .banner-sec {
//   .banner-info{
//   position: relative;
//     z-index: 99;
// }
// }

// .curved-img-hinweise::after {
// content: inherit;
// }
// .curved-img-hinweise::before{
//   width: 46px;
//   height: 46px;
//   background: none;
//   content: "";
//   background: #F2A0B3;
//   border-radius: 60px;
//   top: 40px;

// }

// .curved-img-hinweise::after{

// }

// .footer-new-link{
//   display: flex;
//   justify-content: space-between;
//   a {
//     text-decoration: none;
//   }
// }

// .powered-by{
//   display: flex;
//   align-items: center;
//   justify-content: flex-end;
// }
// @media screen and (max-width :767px){
//   .footer-new-link {
//     display: block  !important ;
//     margin-top: 20px;
//   a{
//     display: block;
//     margin-bottom: 10px;
//   }

// }
//   .powered-by {
//     justify-content: start;
//   }

//   .banner-sec::before{display: none ;}
//   .curved-img::before{ top: 0px;
//   left: 20px;right: inherit; transform: scale(.5);}
//   .curved-img::after {top: 10px;
//     right: 130px;transform: scale(.5);}
//     .footer-logo img {width:130px;}
//     .footer-text-div {margin-bottom: 40px;}
//     .social-media {margin-bottom: 40px;}
//     .curved-img-hinweise {margin-bottom: 45px;}
//     .bottom-footer a{ display: block;}
//     .curved-img-hinweise::before{
//       left: 0px;
//     }

// .curved-img-hinweise::after{
//   content:url('./Ellipse-122.svg');top: 30px;
//   right: 130px;
//   transform: scale(1);
// }
// }

// .bg-deem{
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   background: rgba(0, 0, 0, 0.5411764706);
//   display: none;
//   padding-top: 70px;
//   z-index: 9999;
//   display: block;
// }
