@import '~bootstrap/scss/mixins/breakpoints';
@import '../../../../theme/function.scss';
@import '../../../../theme/variables.scss';
@import '../../../../theme/mixins.scss';
@import '../../../../theme/typography.scss';

.img-name {
  @include media-breakpoint-down(md) {
    position: relative;
    right: 80px !important;
  }
  @include media-breakpoint-down(sm) {
    position: relative;
    right: 50px !important;
  }
}
