@import '~bootstrap/scss/mixins/breakpoints';
@import '../../../theme/function.scss';
@import '../../../theme/variables.scss';
@import '../../../theme/mixins.scss';
@import '../../../theme/typography.scss';

.gray-shade-5 {
  &:before {
    background: url('~/public/assets/images/gray-curve-shade-5.svg') no-repeat;
    background-size: cover;
    position: absolute;
    top: rem(-156);
    content: '';
    width: 100%;
    left: 0;
    height: rem(160);
    @include media-breakpoint-down(sm) {
      background: url('~/public/assets/images/gray-curve-shade-2-m.svg') no-repeat;
      background-size: 100%;
      height: rem(60);
    }
  }
}

.service-gray-shade-2 {
  &:before {
    background: url('~/public/assets/images/project-gray-curve-shade2.svg') center no-repeat;
    content: '';
    width: 100%;
    left: 0;
    height: rem(160);
    background-size: cover;
    position: absolute;
    top: rem(-158);
    @include media-breakpoint-down(xl) {
      height: rem(120);
      top: rem(-120);
    }
    @include media-breakpoint-down(lg) {
      height: rem(72);
      top: rem(-72);
    }
    @include media-breakpoint-down(sm) {
      background: url('~/public/assets/images/gray-curve-shade-4-m.svg') center no-repeat;
      background-size: 100%;
      height: rem(80);
      top: rem(-80);
    }
  }
  // &.gray-shade-1 {
  //   &:after{
  //     bottom: rem(-156);
  //     @include media-breakpoint-down(xl) {
  //       bottom: rem(-100);
  //     }
  //     @include media-breakpoint-down(lg) {
  //       bottom: rem(-72);
  //     }
  //     @include media-breakpoint-down(sm) {
  //       bottom: rem(-84);
  //       height: rem(100);
  //       background-size: 100%;
  //     }
  //   }
  // }
}

.service-list {
  li {
    span {
      width: rem(46);
      height: rem(46);
      min-width: rem(46);
    }
  }
}
.eu-social-section{
	// &.gray-shade-1{
	// 	&:after{
	// 		bottom: rem(-160);
	// 		@include media-breakpoint-down(xl) {
	// 			bottom: rem(-100);
	// 		}
	// 		@include media-breakpoint-down(lg) {
	// 			bottom: rem(-72);
	// 		}
	// 		@include media-breakpoint-down(sm) {
	// 			bottom: rem(-66);
	// 			height: rem(100);
	// 			background-size: 100%;
	// 		}
	// 	}
	// }
	&.gray-shade-5{
		&:before{
			@include media-breakpoint-down(xl) {
				height: rem(88);
				top: rem(-80);
			}
			@include media-breakpoint-down(lg) {
				height: rem(64);
				top: rem(-60);
			}
		}
	}
	.w-50{
		@include media-breakpoint-down(sm) {
			width: 100% !important;
		}
	}
}

h1 {
  &.roaming-heading {
    @include media-breakpoint-down(sm) {
      font-size: 33px;
    }
  }
}
