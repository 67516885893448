.action-box-parent {
  position: fixed;
  transition: right 0.3s;
  -webkit-transition: right 0.3s;
  right: -300px;
  margin-right: -5px;
  padding-right: 20px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  max-width: 450px;
  top: 50%;
  &.show {
    right: 0;
  }
  @media only screen and (min-width: 0px) and (max-width: 567px) {
    max-width: 90vw;
    scale: 0.8;
    right: calc(0.8 * -300px);

    &.show {
      right: -40px;
    }
  }
}
