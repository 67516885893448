@import '~bootstrap/scss/functions', '~bootstrap/scss/variables';
// colors variables
$off-white: #efece8;
$onyx-dark: #121212;
$purple: #534164;
$pink: #e999ab;
$coral: #f39e72;
$mango: #f9bb5a;
$yellow: #ffdc69;
$purple-light: #786886;
$success: #75DA79;
$error: #f43f5e;
$disabled: #d0d0d0;
$purple-text: #503a64;
$pink-text: #f2a0b3;
$grey: #626b6f;
$link-hover: #150E1C;

// colors-map
$colors: (
  'off-white': $off-white,
  'purple': $purple,
  'pink': $pink,
  'coral': $coral,
  'mango': $mango,
  'yellow': $yellow,
  'purple-light': $purple-light,
  'purple-text': $purple-text,
  'pink-text': $pink-text,
  'grey': $grey,
  'error': $error,
  'disabled': $disabled,
  'info': $mango
);

// Theme color variables
$primary: $purple;
$secondary: $off-white;
$error: $error;

// $success:       $success;
// $warning:       $warning;

// theme-color variables
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'error': $error,
  'info': $mango
  // "success":    $success,
  // "warning":    $warning,
  // "danger":     $danger,
);

// font-family variables
$font-family-patron-regular: 'Patron-Regular';
$font-family-patron-medium: 'Patron-Medium';
$font-family-patron-black: 'Patron-Black';
$font-family-notosans-regular: 'NotoSans-Regular';
$font-family-notosans-bold: 'NotoSans-Bold';

// border radius variables
$btn-radius: 50px;
$box-radius: 12px;
$input-border-radius: 8px !important;
$modal-border-radius:16px;

// Border colour variables
$border-primary: $purple;
$border-light-purple: $purple-light;

// font-size Variables
$h1-font-size: 80px;
$font-size-60: 60px;
$h2-font-size: 52px;
$h3-font-size: 40px;
$h4-font-size: 36px;
$h5-font-size: 28px;
$font-size-md: 20px;
$font-size-xs: 14px;

// line-heights variables
// $line-height-base: 1.5px;
$line-height-big: 140%;
$line-height-medium: 113%;
$line-height-normal: 100%;

// letter-spacing variables
$character-spacing-0-16: -0.16px;
$character-spacing-0-2: -0.2px;
$character-spacing-1: 1px;

//input padding variables
$input-padding-y: 17px;
$input-padding-x: 16px;
$btn-padding-y: $input-padding-y;
$btn-padding-x: 32px;
$modal-padding-x:48px;
$modal-padding-y:40px;
$alert-padding-y:12px;

// scss-docs-start container-max-widths
$container-max-width: 1200px;
$container-max-widths: (
  xxl: 1200px
);

//radio button variables
$form-check-input-width: 20px;
$form-check-min-height: 20px;

// buttons height
$btn-height: 42px;


// -----------------------------------
// Spacing
// -----------------------------------

// $spacer: 1rem !default;
// $spacers: (
//   0: 0,
//   1: $spacer * .25,
//   2: $spacer * .5,
//   3: $spacer,
//   4: $spacer * 1.5,
//   5: $spacer * 3,
// ) !default;

// Extended spacer values
$spacers: (
  56: 56px,
  80: 80px,
);