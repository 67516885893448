@import '~bootstrap/scss/mixins/breakpoints';
@import '../../../../theme/function.scss';
@import '../../../../theme/variables.scss';
@import '../../../../theme/mixins.scss';
@import '../../../../theme/typography.scss';

.questions-clarified-section {
  &.purple-curve-shade-2 {
    &:after {
      @include media-breakpoint-down(lg) {
        bottom: rem(-65);
        height: rem(68);
      }
    }
    &.quest-section {
      &:after {
        z-index: 1;
        background: url('~/public/assets/images/purple-dot-shade-1.svg') no-repeat;
        background-size: 100%;
        @include media-breakpoint-down(sm) {
          background: url('~/public/assets/images/project-top-purple-curve-shade1-mobile.svg') no-repeat;
          background-size: 100%;
        }
      }
    }
  }
}
.frequently-topic-section {
  &.gray-shade-3 {
    &:before {
      @include media-breakpoint-down(sm) {
        background: url('~/public/assets/images/gray-curve-shade-4-m.svg') no-repeat;
        background-size: 100%;
        height: rem(100);
        top: rem(-77);
      }
    }
  }
  &.gray-shade-1{
    &:after {
      @include media-breakpoint-down(sm) {
        background: url('~/public/assets/images/gray-curve-shade-1-m.svg') no-repeat;
        background-size: 100%;
        height: rem(78);
      }
    }
  }
}
.questions-clarified-section {
  &.friends-purple-shade-2 {
    &:before {
      @include media-breakpoint-down(sm) {
        background: url('~/public/assets/images/friends-purple-curve-shade-4-mobile.svg')
          no-repeat;
          background-size: cover;
          height: rem(77);
          top: rem(-77);
      }
    }
    &:after {
      @include media-breakpoint-down(sm) {
        background: url('~/public/assets/images/project-top-purple-curve-shade1-mobile.svg')
          no-repeat;
        background-size: 100%;
        height: rem(78);
      }
    }
  }
}
