@import "../../../../../node_modules/bootstrap/scss/mixins/breakpoints";
@import '../../../../theme/function.scss';
@import "../../../../theme/variables.scss";


.body-large-regular {
    &.body-large-20 {
        @include media-breakpoint-down(sm) {
            font-size: rem(20);
        }
    }
}

.heading-small {
    &.font-16 {
        @include media-breakpoint-down(sm) {
            font-size: rem(16);
            line-height: rem(22);
        }
    }
}