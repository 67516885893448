@import "../../../../../node_modules/bootstrap/scss/mixins/breakpoints";
@import '../../../../theme/function.scss';
@import "../../../../theme/variables.scss";

.offWhite-color-text {
    &.font-13 {
        @include media-breakpoint-down(sm) {
            font-size: rem(13);
            // line-height: rem(22);
        }
    }
}