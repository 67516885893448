// @import '@theme/variables.scss';
@import '~bootstrap/scss/mixins/breakpoints';
@import '../../../theme/function.scss';
@import '../../../theme/variables.scss';
@import '../../../theme/mixins.scss';
@import '../../../theme/typography.scss';


.white-shade-1:after {
  background: url('~/public/assets/images/white-curve-shade-1.svg') center no-repeat;
  background-size: cover;
  position: absolute;
  bottom: rem(120);
  content: '';
  width: 100%;
  left: 0;
  height: rem(120);
}

.gray-shade-1:after {
  background: url('~/public/assets/images/gray-curve-shade-1.svg') no-repeat;
  background-size: cover;
  position: absolute;
  bottom: 0;
  content: '';
  width: 100%;
  left: 0;
  /* height: rem(160); */
  height: 30%;

  @include media-breakpoint-down(xxl) {
    height: rem(120);
  }

  @include media-breakpoint-down(xl) {
    height: rem(100);
  }

  @include media-breakpoint-down(lg) {
    height: rem(92);
  }

  @include media-breakpoint-down(md) {
    height: rem(65);
  }

  @include media-breakpoint-down(sm) {
    height: rem(68);
    bottom: rem(-34);
    background: url('~/public/assets/images/gray-curve-shade-1-m.svg') no-repeat;
    background-size: contain;
    background-position: initial;
  }
}

.gray-shade-2:after {
  background: url('~/public/assets/images/gray-curve-shade-2.svg') center no-repeat;
  background-size: cover;
  position: absolute;
  bottom: 0;
  content: '';
  width: 100%;
  left: 0;
  height: 160px;

  @include media-breakpoint-down(xxl) {
    height: rem(120);
  }

  @include media-breakpoint-down(xl) {
    height: rem(100);
  }

  @include media-breakpoint-down(lg) {
    height: rem(75);
  }

  @include media-breakpoint-down(md) {
    height: rem(65);
  }

  @include media-breakpoint-down(sm) {
    background: url('~/public/assets/images/gray-curve-shade-2-m.svg') center no-repeat;
    background-size: cover;
    height: rem(60);
  }
}

.gray-shade-3:before {
  background: url('~/public/assets/images/gray-curve-shade-3.svg') center no-repeat;
  background-size: cover;
  position: absolute;
  top: rem(-160);
  content: '';
  width: 100%;
  left: 0;
  height: rem(160);

  @include media-breakpoint-down(xxl) {
    height: rem(120);
    top: rem(-120);
  }

  @include media-breakpoint-down(xl) {
    height: rem(100);
    top: rem(-99);
  }

  @include media-breakpoint-down(lg) {
    height: rem(75);
    top: rem(-74);
  }

  @include media-breakpoint-down(md) {
    height: rem(65);
    top: rem(-65);
  }

  @include media-breakpoint-down(sm) {
    background: url('~/public/assets/images/gray-curve-shade-3-m.svg') center no-repeat;
    background-size: cover;
    height: rem(60);
    top: rem(-55);
  }
}

.friends-purple-shade-1:after {
  background: url('~/public/assets/images/friends-purple-curve-shade-2.svg') center no-repeat;
  background-size: cover;
  position: absolute;
  bottom: rem(-34);
  content: '';
  width: 100%;
  left: 0;
  height: rem(158);
  z-index: 1;
  // transform: rotate(-180deg);
  // @include media-breakpoint-down(xl) {
  // 	bottom: rem(-69);
  // 	height: rem(105);
  // }

  // @include media-breakpoint-down(lg) {
  // 	bottom: rem(-48);
  // 	height: rem(64);
  // }
  @include media-breakpoint-down(xxl) {
    height: rem(120);
  }

  @include media-breakpoint-down(xl) {
    height: rem(100);
  }

  @include media-breakpoint-down(lg) {
    height: rem(75);
  }

  @include media-breakpoint-down(md) {
    height: rem(65);
  }

  @include media-breakpoint-down(sm) {
    height: rem(90);
    bottom: rem(-30);
    background: url('~/public/assets/images/friends-purple-curve-shade-2-mobile.svg') center no-repeat;
    background-size: 100%;
  }
}

.friends-purple-shade-2:before {
  background: url('~/public/assets/images/friends-purple-curve-shade-1.svg') center no-repeat;
  background-size: cover;
  position: absolute;
  top: rem(-156);
  content: '';
  width: 100%;
  left: 0;
  height: rem(160);

  // @include media-breakpoint-down(xl) {
  // 	top: rem(-84);
  // }
  // @include media-breakpoint-down(lg) {
  // 	top: rem(-62);
  // }
  @include media-breakpoint-down(xxl) {
    height: rem(120);
    top: rem(-120);
  }

  @include media-breakpoint-down(xl) {
    height: rem(100);
    top: rem(-100);
  }

  @include media-breakpoint-down(lg) {
    height: rem(75);
    top: rem(-74);
  }

  @include media-breakpoint-down(md) {
    height: rem(65);
    top: rem(-65);
  }

  @include media-breakpoint-down(sm) {
    background: url('~/public/assets/images/friends-purple-curve-shade-1-mobile.svg') center no-repeat;
    background-size: 100%;
    height: rem(150);
    top: rem(-85);
  }
}

.friends-purple-shade-3:after {
  background: url('~/public/assets/images/friends-purple-curve-shade-3.svg') no-repeat;
  background-size: cover;
  position: absolute;
  bottom: rem(-160);
  content: '';
  width: 100%;
  left: 0;
  height: rem(160);
  z-index: 1;
  // transform: rotate(-180deg);
  // @include media-breakpoint-down(xl) {
  // 	bottom: rem(-117);
  // }

  // @include media-breakpoint-down(lg) {
  // 	bottom: rem(-60);
  // 	height: rem(83);
  // }
  @include media-breakpoint-down(xxl) {
    height: rem(120);
    bottom: rem(-120);
  }

  @include media-breakpoint-down(xl) {
    height: rem(100);
    bottom: rem(-100);
  }

  @include media-breakpoint-down(lg) {
    height: rem(75);
    bottom: rem(-75);
  }

  @include media-breakpoint-down(md) {
    height: rem(65);
    bottom: rem(-65);
  }

  @include media-breakpoint-down(sm) {
    height: rem(60);
    bottom: rem(-60);
  }
}

.purple-shade-1:after {
  background: url('~/public/assets/images/purple-shade-1.svg') center no-repeat;
  background-size: cover;
  position: absolute;
  bottom: 0;
  content: '';
  width: 100%;
  left: 0;
  height: rem(160);

  @include media-breakpoint-down(xxl) {
    height: rem(120);
  }

  @include media-breakpoint-down(xl) {
    height: rem(100);
  }

  @include media-breakpoint-down(lg) {
    height: rem(70);
  }

  @include media-breakpoint-down(sm) {
    height: rem(80);
    background: url('~/public/assets/images/purple-shade-1-m.svg') center no-repeat;
    background-size: cover;
  }
}

.purple-shade-2:after {
  background: url('~/public/assets/images/purple-shade-2.svg') center no-repeat;
  background-size: cover;
  position: absolute;
  bottom: 0;
  content: '';
  width: 100%;
  left: 0;
  height: rem(160);

  @include media-breakpoint-down(xxl) {
    height: rem(120);
  }

  @include media-breakpoint-down(xl) {
    height: rem(100);
  }

  @include media-breakpoint-down(lg) {
    height: rem(70);
  }

  @include media-breakpoint-down(sm) {
    height: rem(80);
    background: url('~/public/assets/images/purple-shade-2-m.svg') center no-repeat;
    background-size: cover;
  }
}

.orange-gray-bubble:before {
  background: url('~/public/assets/images/orange-gray-bubble.svg') center no-repeat;
  position: absolute;
  content: '';
  width: 100%;
  left: rem(-126);
  height: rem(126);
  top: rem(126);

  @include media-breakpoint-down(xl) {
    display: none;
  }
}

.yellow-pink-bubble {
  &:before {
    background: url('~/public/assets/images/yellow-pink-bubble.svg') center no-repeat;
    position: absolute;
    content: '';
    width: 100%;
    height: rem(595);
    left: rem(-70);
    top: rem(70);

    @include media-breakpoint-down(xl) {
      display: none;
    }
  }
}

.orange-white-bubble:before {
  background: url('~/public/assets/images/orange-white-bubble.svg') center no-repeat;
  position: absolute;
  content: '';
  width: 100%;
  left: rem(-126);
  height: rem(126);
  top: rem(126);

  @include media-breakpoint-down(xl) {
    display: none;
  }
}

.orange-yellow-bubble:before {
  background: url('~/public/assets/images/orange-yellow-bubble.svg') center no-repeat;
  position: absolute;
  content: '';
  width: 100%;
  left: rem(-126);
  height: rem(126);
  top: rem(63);

  @include media-breakpoint-down(xl) {
    display: none;
  }
}

.orange-white-bubble-2:before {
  background: url('~/public/assets/images/orange-white-bubble-2.svg') center no-repeat;
  position: absolute;
  content: '';
  width: 100%;
  height: rem(514);
  left: rem(-90);
  bottom: rem(-30);
  z-index: 1;

  @include media-breakpoint-down(xl) {
    display: none;
  }
}

.gray-can-shade:after {
  background: url('~/public/assets/images/gray-can-shade.svg') center no-repeat;
  background-size: cover;
  position: absolute;
  bottom: rem(-160);
  content: '';
  width: 100%;
  left: 0;
  height: rem(160);

  @include media-breakpoint-down(xxl) {
    height: rem(120);
    bottom: rem(-120);
  }

  @include media-breakpoint-down(xl) {
    height: rem(100);
    bottom: rem(-100);
  }

  @include media-breakpoint-down(lg) {
    height: rem(75);
    bottom: rem(-75);
  }

  @include media-breakpoint-down(md) {
    height: rem(65);
    bottom: rem(-65);
  }

  @include media-breakpoint-down(sm) {
    height: rem(68);
    bottom: rem(-68);
    background: url('~/public/assets/images/gray-can-shade-m.svg') center no-repeat;
    background-size: cover;
  }
}

//Project data page css start//
.project-purple-shade-1:after {
  background: url("~/public/assets/images/project-top-purple-curve-shade1.svg") no-repeat;
  background-size: cover;
  position: absolute;
  bottom: rem(-140);
  content: "";
  width: 100%;
  left: 0;
  /* height: rem(160); */
  height:32%;
  z-index: 1;

  @media only screen and (max-width: 6000px) and (min-width: 2100px)  {height:250px; bottom: -14.75rem;}

  @include media-breakpoint-down(xxl) {
    height: 120px;
    bottom: -115px;
  }

  @include media-breakpoint-down(xl) {
    height: 100px;
    bottom: -95px;
  }

  @include media-breakpoint-down(lg) {
    height: 70px;
    bottom: -65px;
  }

  @include media-breakpoint-down(md) {
    height: 70px;
    bottom: -65px;
  }

  @include media-breakpoint-down(sm) {
    background-size: cover;
    height: 68px;
    bottom: -65px;
  }
}

.project-image-shade-1::after {
  background: url("~/public/assets/images/project-banner-curve-shade1.svg") center no-repeat;
  background-size: cover;
  position: absolute;
  bottom: -5px;
  content: "";
  width: 100%;
  left: 0;
  height: rem(160);
  z-index: 1;

  @media only screen and (max-width: 6000px) and (min-width: 2100px)  {height:220px;}

  @include media-breakpoint-down(xxl) {
    height: 120px;
    bottom: -5px;
  }

  @include media-breakpoint-down(xl) {
    height: 100px;
    bottom: -5px;
  }

  @include media-breakpoint-down(lg) {
    height: 75px;
    bottom: -5px;
  }

  @include media-breakpoint-down(md) {
    height: 65px;
    bottom: -5px;
  }

  @include media-breakpoint-down(sm) {
    background: url("~/public/assets/images/project-banner-curve-shade1-mobile.svg") no-repeat;
    background-size: cover;
    height: 80px;
    bottom: -5px;
  }
}

.project-share-details {
  &.gray-top-shade::before {
    background: url("~/public/assets/images/project-top-gray-curve-shade1.svg") no-repeat;
    content: "";
    width: 100%;
    left: 0;
    height: rem(160);
    background-size: cover;
    position: absolute;
    top: rem(-158);

    @include media-breakpoint-down(xl) {
      height: rem(120);
      top: rem(-120);
    }

    @include media-breakpoint-down(lg) {
      height: rem(100);
      top: rem(-100);
    }

    @include media-breakpoint-down(sm) {
      background: url("~/public/assets/images/project-top-gray-curve-shade1-mobile.svg") no-repeat;
      background-size: cover;
      height: rem(84);
      top: rem(-25);
      background-position: center;
    }
  }
}

.project-share-formet-gray-shade-2::before {
  background: url("~/public/assets/images/project-gray-curve-shade2.svg") center no-repeat;
  content: "";
  width: 100%;
  left: 0;
  height: rem(160);
  background-size: cover;
  position: absolute;
  top: rem(-160);

  @include media-breakpoint-down(xxl) {
    height: rem(120);
    top: rem(-120);
  }

  @include media-breakpoint-down(xl) {
    height: rem(100);
    top: rem(-100);
  }

  @include media-breakpoint-down(lg) {
    height: rem(75);
    top: rem(-75);
  }

  @include media-breakpoint-down(md) {
    height: rem(65);
    top: rem(-65);
  }

  @include media-breakpoint-down(sm) {
    background: url("~/public/assets/images/gray-curve-shade-2-m.svg") no-repeat;
    background-size: cover;
    height: rem(70);
    top: rem(-67);
  }
}

.project-top-banner {
  &.pt-10 {
    @include media-breakpoint-down(sm) {
      padding: 8rem 0 2rem;
    }
  }

  .ps-5 {
    @include media-breakpoint-down(sm) {
      padding-left: rem(15) !important;
    }
  }
}

.project-digital-pltform {
  .ps-5 {
    @include media-breakpoint-down(sm) {
      padding-left: rem(15) !important;
    }
  }

  .pt-10 {
    @include media-breakpoint-down(xl) {
      padding-top: 0 !important;
    }

    @include media-breakpoint-down(lg) {
      padding-top: 0 !important;
    }
  }
}

.project-share-details {
  .image-block {
    @include media-breakpoint-down(md) {
      text-align: right;
     // margin-top: -20px !important;
      margin-top: -100px !important;
      position: relative;
      display: flex;
      justify-content: flex-end;
    }
  }
}

.project-btm-banner {
  &.mt-4 {
    @include media-breakpoint-down(xl) {
      margin-top: rem(-46) !important;
    }

    @include media-breakpoint-down(lg) {
      margin-top: rem(-46) !important;
    }

    @include media-breakpoint-down(sm) {
      margin-top: rem(32) !important;
    }
  }
}

.project-help-row {
  .pt-10 {
    @include media-breakpoint-down(lg) {
      padding-top: 4rem !important;
    }

    @include media-breakpoint-down(sm) {
      padding-top: 0 !important;
      padding-left: 15px !important;
      padding-bottom: 0 !important;
    }
  }
}

.project-share-formet-row {
  .share-project-img {
    @include media-breakpoint-down(lg) {
      right: 0;
    }

    @include media-breakpoint-down(md) {
      width: 100%;
      top: -130px;
      bottom: inherit !important;
      right: 0;
    }

    @include media-breakpoint-down(sm) {
      top: -130px;
      bottom: inherit !important;
      right: 0;
    }
  }

  .ps-5 {
    @include media-breakpoint-down(sm) {
      padding: 0 15px !important;
    }
  }

  button {
    @include media-breakpoint-down(xl) {
      width: 75% !important;
    }

    @include media-breakpoint-down(sm) {
      width: 100% !important;
    }
  }

  .pt-10 {
    @include media-breakpoint-down(xl) {
      padding-top: 0 !important;
    }

    @include media-breakpoint-down(lg) {
      padding-top: 0 !important;
    }
  }
}

//Project data page css end//
h5.cnt {
  @include media-breakpoint-down(sm) {
    font-size: rem(20);
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.share-mobile-section {
  .tariff-link:hover {
    color: $white !important;
    text-decoration-color: $white !important;
  }

  .tariff-link:focus {
    color: $white !important;
    text-decoration-color: $white !important;
  }
}

.sim-activation-sec {
  .tariff-link:hover {
    color: $white !important;
    // color: $link-hover !important;
    text-decoration-color: $white !important;
  }

  .tariff-link:focus {
    color: $white !important;
    text-decoration-color: $white !important;
  }

  .tariff-code {
    margin-left: -20px;

    @include media-breakpoint-down(xl) {
      margin-left: rem(55);
    }
    @include media-breakpoint-down(lg) {
      margin-left: rem(135);
    }
    @include media-breakpoint-down(md) {
      margin-left: rem(30);
    }
    
    @include media-breakpoint-down(sm) {
      margin-left: rem(32);
    }
  }
}