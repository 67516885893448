@import '~bootstrap/scss/mixins/breakpoints';
@import '../../../theme/function.scss';
@import '../../../theme/variables.scss';
@import '../../../theme/mixins.scss';
@import '../../../theme/typography.scss';

.top-section {
  .container {
    position: relative;
  }
  .swoosh-element.bottom {
    z-index: 0;
    position: relative;
    margin-top: -50px;
  }
}

.home-heading {
  color: $purple-text;
  font-size: 52px;
  line-height: 113%; 

  // @include media-breakpoint-down(xxl) {
  //   font-size: 52px;
  // }

  // @include media-breakpoint-down(xl) {
  //   font-size: 52px;
  // }

  @include media-breakpoint-down(lg) {
    font-size: 40px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 27px !important;
  }
}

.share-bnr-mobile-section {
  // background-image: url('~/public/assets/images/share-mobile-home-banner.svg');
  background-repeat: no-repeat !important;
  background-size: 100% !important;
  background-position: center !important;
  height: calc(100vh - 100px) !important;
  z-index: 0;

  @include media-breakpoint-down(xxl) {
    background-position: top !important;
    height: 960px !important;
  }

  @include media-breakpoint-down(xl) {
    background-position: top !important;
    height: 780px !important;
  }

  @include media-breakpoint-down(lg) {
    background-position: top !important;
    height: 600px !important;
  }

  @include media-breakpoint-down(md) {
    background-position: top !important;
    height: 600px !important;
  }

  // .swoosh-element.bottom {
  //   margin-top: -150px;
  // }
  // @include media-breakpoint-down(sm) {
  //   height: 550px !important;
  // }

  // @include media-breakpoint-down(xs) {
  //   height: 550px !important;
  // }

  // @include media-breakpoint-down(xs) {
  //   height: rem(400);
  // }

  // &:after {
  //   background: url('~/public/assets/images/home-curve-shade-1.svg') center no-repeat;
  //   background-size: cover;
  //   position: absolute;
  //   bottom: rem(-2);
  //   content: '';
  //   width: 100%;
  //   left: 0;
  //   z-index: 1;
  //   height: 25%;

  //   @include media-breakpoint-down(xxl) {
  //     height: rem(120);
  //   }

  //   @include media-breakpoint-down(xl) {
  //     height: rem(100);
  //   }

  //   @include media-breakpoint-down(lg) {
  //     height: rem(75);
  //   }

  //   @include media-breakpoint-down(md) {
  //     height: rem(65);
  //   }

  //   @include media-breakpoint-down(sm) {
  //     height: rem(60);
  //     bottom: 0;
  //   }
  // }

  // &:before {
  //   background: url('~/public/assets/images/home-curve-shade-2.svg') center no-repeat;
  //   background-size: cover;
  //   position: absolute;
  //   top: rem(-2);
  //   content: '';
  //   width: 100%;
  //   left: 0;
  //   z-index: 1;
  //   height: 25%;

  //   @include media-breakpoint-down(xxl) {
  //     height: 50%;
  //   }

  //   @include media-breakpoint-down(xl) {
  //     height: rem(100);
  //   }

  //   @include media-breakpoint-down(lg) {
  //     height: rem(75);
  //   }

  //   @include media-breakpoint-down(md) {
  //     height: rem(65);
  //   }

  //   @include media-breakpoint-down(sm) {
  //     height: rem(60);
  //     top: 0;
  //   }
  // }
}

.share-mobile-top-section {
  // background-image: url('~/public/assets/images/share-mobile-home-banner.svg');
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  height: rem(520);

  @include media-breakpoint-down(md) {
    background-position: top !important;
    height: rem(430);
  }

  @include media-breakpoint-down(sm) {
    height: rem(320);
  }

  &:after {
    background: url('~/public/assets/images/home-curve-shade-1.svg') center no-repeat;
    background-size: cover;
    position: absolute;
    bottom: rem(-2);
    content: '';
    width: 100%;
    left: 0;
    z-index: 1;
    height: rem(160);

    @include media-breakpoint-down(xxl) {
      height: rem(120);
    }

    @include media-breakpoint-down(xl) {
      height: rem(100);
    }

    @include media-breakpoint-down(lg) {
      height: rem(75);
    }

    @include media-breakpoint-down(md) {
      height: rem(65);
    }

    @include media-breakpoint-down(sm) {
      height: rem(60);
      bottom: 0;
    }
  }
}

.get-more-gb-row {
  &.phone-tariff-section {
    h4.primary-color-text {
      @include media-breakpoint-down(sm) {
        padding-top: 0 !important;
      }
    }

    &:before {
      background-image: url('~/public/assets/images/project-top-gray-curve-shade1.svg');
      background-repeat: no-repeat;
      content: '';
      width: 100%;
      left: 0;
      height: rem(160);
      background-size: cover;
      position: absolute;
      bottom: rem(-2);
      z-index: -1;

      @include media-breakpoint-down(xxl) {
        height: rem(120);
      }

      @include media-breakpoint-down(xl) {
        height: rem(100);
      }

      @include media-breakpoint-down(lg) {
        height: rem(75);
      }

      @include media-breakpoint-down(md) {
        height: rem(65);
      }

      @include media-breakpoint-down(sm) {
        background-image: url('~/public/assets/images/gray-curve-shade-2-m.svg');
        background-repeat: no-repeat;
        height: rem(80);
      }
    }

    .w-50 {
      @include media-breakpoint-down(sm) {
        width: 100% !important;
      }
    }

    .mobile-100 {
      @include media-breakpoint-down(sm) {
        margin-bottom: 28px !important;
      }
    }

    @include media-breakpoint-down(md) {
      margin-top: rem(500);
    }

    @include media-breakpoint-down(sm) {
      margin-top: rem(730);
    }

    // @include media-breakpoint-down(xs) {
    //   // margin-top: rem(800);
    //   margin-top: 800px;
    // }
  }

  &.pt-10 {
    // @include media-breakpoint-down(xl) {
    // 	padding: 0 !important;
    // }
    // @include media-breakpoint-down(lg) {
    // 	padding: 0 !important;
    // }
  }
}

.purple-curve-shade-2:after {
  background-image: url('~/public/assets/images/purple-curve-shade-2.svg');
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  bottom: rem(-154);
  content: '';
  width: 100%;
  left: 0;
  height: rem(160);
  z-index: -1;

  // transform: rotate(-180deg);
  @include media-breakpoint-down(xl) {
    bottom: rem(-158);
  }

  @include media-breakpoint-down(lg) {
    bottom: rem(-120);
    height: rem(121);
  }

  @include media-breakpoint-down(md) {
    bottom: rem(-79);
    height: rem(80);
  }

  @include media-breakpoint-down(sm) {
    height: rem(60);
    bottom: rem(-30);
  }
}

.share-mobileApp-section {
  &.purple-curve-shade-2:after {
    @include media-breakpoint-down(xl) {
      bottom: rem(-158);
    }

    @include media-breakpoint-down(lg) {
      bottom: rem(-64);
      height: rem(66);
    }

    @include media-breakpoint-down(sm) {
      background-image: url('~/public/assets/images/purple-curve-shade-mobile.svg');
      bottom: rem(-68);
      height: rem(80);
    }
  }

  &.purple-curve-shade-2:before {
    @include media-breakpoint-down(sm) {
      background: url('~/public/assets/images/friends-purple-curve-shade-4-mobile.svg') no-repeat;
      height: rem(84);
      top: rem(-82);
      background-size: cover;
    }
  }

  // figure {
  //   @include media-breakpoint-down(xl) {
  //     width: 40%;
  //   }
  //   @include media-breakpoint-down(sm) {
  //     position: relative !important;
  //     width: 80%;
  //     margin-left: auto;
  //   }
  // }
}

.home-faq-section {
  &.pt-10 {
    @include media-breakpoint-down(lg) {
      padding-top: rem(30) !important;
    }

    .h4-heading {
      @include media-breakpoint-down(sm) {
        padding-top: rem(60) !important;
      }
    }
  }
}

.donate-section {
  p {
    .head-text {
      @include subheading-typography;
      font-size: $h4-font-size;
    }
  }
}

.surf-donate-section {
  .w-75 {
    @include media-breakpoint-down(lg) {
      width: 100% !important;
    }
  }
}

.already-convinced-section {
  &.friends-purple-shade-2 {
    &:before {
      @include media-breakpoint-down(sm) {
        background: url('~/public/assets/images/friends-purple-curve-shade-4-mobile.svg') no-repeat !important;
        height: rem(86);
        background-size: cover !important;
      }
    }

    &:after {
      @include media-breakpoint-down(sm) {
        background-image: url('~/public/assets/images/purple-curve-shade-5-mobile.svg');
        bottom: rem(-42);
        height: rem(76);
      }
    }
  }
}

.grow {
  transition: all 0.3s ease-in-out;
}

.grow:hover {
  transform: scale(3);
  cursor: none;
}

.mob-tariff {
  .rounded {
    @include media-breakpoint-down(md) {
      margin-top: rem(-120);
    }
  }
}

.tarrif-selection {
  .mango-color-text {
    h2 {
      // @include media-breakpoint-down(lg) {
      font-size: 46px;
      // font-family: $font-family-notosans-bold;
      font-family: $font-family-patron-black;
      line-height: 100%;
      font-weight: 900;
      letter-spacing: -0.46px;
      // }

      // @media only screen and (max-width: 1024px) and (min-width: 992px) {
      //   font-size: 42px;
      // }
      // @include media-breakpoint-down(lg) {
      //   font-size: 42px;
      //   line-height: 120%;
      // }
      // @include media-breakpoint-down(md) {
      //   font-size: 36px;
      //   line-height: 120%;
      // }
      // @include media-breakpoint-down(sm) {
      //   font-size: 30px;
      //   line-height: 100%;
      // }
      // @include media-breakpoint-down(xs) {
      //   font-size: 40px;
      // }
      @media only screen and (max-width: 575.98px) {
        font-size: 39px;
      }
    }
  }
}

.pink-gray-bubble {
  @include media-breakpoint-down(sm) {
    right: 60px !important;
  }
}

.cmn-home-btn {
  width: rem(327);

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.der-head {
  min-height: rem(45);

  @include media-breakpoint-down(lg) {
    min-height: rem(65);
  }

  @include media-breakpoint-down(md) {
    min-height: rem(95);
  }

  @include media-breakpoint-down(sm) {
    min-height: auto;
  }
}
.home-tariff {
  .tariff-shadow {
    box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.25);
    padding: 40px 22px;

    @include media-breakpoint-down(md) {
      margin-top: rem(250);
    }

    @include media-breakpoint-down(sm) {
      margin-top: rem(250);
      padding: 28px 18px;
    }

    .tariff-image {
      top: -30px;
      position: absolute;
      left: 0;
      right: 0;
    }
  }
}

.accordion-item {
  .accordion-button {
    font-size: rem(20);

    .button-text {
      @include media-breakpoint-down(sm) {
        max-width: rem(271);
      }
    }

   .accordion-heading {
      font-size: rem(20);
      font-family: NotoSans-Bold;
      letter-spacing: -.2px;
      line-height: 140%;
    }
  }
}
.tariff-voucher-top {
  color: $purple;
  text-align: center;
  font-family: $font-family-notosans-bold;
  //font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: 113%;
  min-width: 170px;
  width: fit-content;
  padding: 0 15px;
  margin: -60px auto 0;
  background: $mango;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
}
#select-tariff {
  @include media-breakpoint-down(sm) {
    background-size: 100% !important;
  }
  @include media-breakpoint-down(md) {
    background-size: 100% !important;
  }
}
.navbar-collapse .overview-powered-logo {
  // display: none !important;
  display: flex !important;
  // @include media-breakpoint-down(md) {
  // }
}

.share-mobile-cell-section {
  @include media-breakpoint-down(md) {
    margin-top: 600px;
  }
}

.image-div {
  .img-5g {
    @include media-breakpoint-down(sm) {
      width: 100px;
    }
  }
}

.home-headline {
  font-size: 52px !important;
  // @media only screen and (min-width: 0px) and (max-width: 768px) {
  // }
  @include media-breakpoint-down(lg) {
    font-size: 40px !important;
  }
  @include media-breakpoint-down(md) {
    font-size: 40px !important;
  }
  @include media-breakpoint-down(sm) {
    font-size: 28px !important;
  }
}
video {
  height: 98% !important;
  @include media-breakpoint-down(sm) {
    height: 97% !important;
  }
}

video::-webkit-media-controls-play-button {
  display: flex !important;
}
