@import '~bootstrap/scss/bootstrap';
@import '~bs-stepper/dist/css/bs-stepper.min.css';
@import '~bootstrap-icons/font/bootstrap-icons.css';

@import './function.scss';
@import './variables.scss';
@import './mixins.scss';
@import './typography.scss';

// Animation css
@import '~animate.css/animate.css';

// Fontawesome css
@import '~font-awesome/css/font-awesome.css';

// container
.container {
  max-width: $container-max-width;
}
// classes for color variables
.bg-primary-color {
  background-color: $purple;
}
.primary-color-text {
  color: $purple-text !important;
}

.bg-white-color {
  background-color: $white;
}
.white-color-text {
  color: $white;
}

.bg-offWhite-color {
  background-color: $secondary;
}
.offWhite-color-text {
  color: $secondary;
}

.bg-pink-color {
  background-color: $pink;
}
.pink-color-text {
  color: $pink-text;
}

.bg-coral-color {
  background-color: $coral;
}
.coral-color-text {
  color: $coral;
}

.bg-mango-color {
  background-color: $mango;
}
.mango-color-text {
  color: $mango;
}

.bg-yellow-color {
  background-color: $yellow;
}
.yellow-color-text {
  color: $yellow;
}

.bg-purple-light-color {
  background-color: $purple-light;
}
.purple-light-color-text {
  color: $purple-light;
}
.bg-grey-color {
  background-color: $grey;
}
.grey-color-text {
  color: $grey;
}

.bg-success-color {
  background-color: $success;
}
.success-color-text {
  color: $success;
}

.bg-error-color {
  background-color: $error;
}
.error-color-text {
  color: $error;
}

.bg-disabled-color {
  background-color: $disabled;
}
// Border color class
.border-primary {
  border-color: $border-primary !important;
}
.border-light-purple {
  border-color: $border-light-purple !important;
}

// Smooth scrolling
html {
  scroll-behavior: smooth;
  scroll-padding: 100px;
}
