@import '~bootstrap/scss/mixins/breakpoints';
@import '../../../../theme/function.scss';
@import '../../../../theme/variables.scss';
@import '../../../../theme/mixins.scss';
@import '../../../../theme/typography.scss';

.app-alert {
  position: relative;
}
.app-alert-list {
  z-index: 100;
  position: fixed;
  max-width: 1098px;
  min-width: rem(327);
  letter-spacing: -0.16px;
  // height: rem(44);
  left: -75px;
  right: 0;
  margin: 0 auto;
  top: 150px; 
  @include media-breakpoint-down(xl) {
    max-width: 91%;
  }
  @include media-breakpoint-down(lg) {
    max-width: 95%;
  }
  @include media-breakpoint-down(md) {
    max-width: 90%;
  }
  @include media-breakpoint-down(sm) {
    max-width: 88%;
    left: 0;
  }
}

.alert{
  justify-content: center;
  @include media-breakpoint-down(sm) {
    justify-content: left;
  }
  &.font-16{
    font-size: rem(16);
  }
}
