@import "~bootstrap/scss/mixins/breakpoints";
@import '../../../theme/function.scss';
@import '../../../theme/variables.scss';
@import '../../../theme/mixins.scss';
@import '../../../theme/typography.scss';

.mob-gray-shade {
    &::before {
        @include media-breakpoint-down(sm) {
            background: url('~/public/assets/images/gray-curve-shade-2-m.svg') center no-repeat !important;
            background-size: cover  !important;
            height: rem(60)  !important;
            top: rem(-55)  !important;
        }
    }
}

.pink-box {
    .font-16 {
        font-size: rem(16);
    }
    
    .font-14 {
        font-size: rem(14);
    }
}

.box-link {
    p {
        margin-bottom: rem(0);
        font-size: rem(14);
        a {
            color: $primary;
            font-family: $font-family-patron-medium;
        }
    }
}

.pink-box-text {
    p {
        margin-bottom: 0px;
    }
}
