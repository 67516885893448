@import '../../../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../../../../theme/function.scss';
@import '../../../../theme/variables.scss';

.tarrif-feature {
  .list-group-item {
    background-image: url('~/public/assets/images/check-icon-old.svg');
    background-repeat: no-repeat;
    background-position: left rem(7);
    padding-left: rem(20);
    span {
      sup {
        .star-btn {
          margin-top: -10px;
        }
      }
    }
  }
}

.tarrif-selection {
  width: 47%;
  border: rem(2) solid $mango;
  border-radius: $input-border-radius;
  padding: rem(12) rem(16);
  cursor: pointer;
  transform: scale(0.95);
  &.active {
    background-color: $mango;
    transform: scale(1.05);
    h2,
    h3,
    p,
    .custom-tariff-name,
    .custom-data-text {
      color: $purple-text;
    }
  }

  .custom-data-text {
    font-size: 46px;
    font-family: $font-family-patron-black;
    line-height: 100%;
    font-weight: 900;
    letter-spacing: -0.46px;
    @media only screen and (max-width: 575.98px) {
      font-size: 39px;
    }
  }

  @include media-breakpoint-down(lg) {
    width: 49%;
    padding: 15px;
  }

  @include media-breakpoint-down(md) {
    width: 49%;
    padding: 12px;
  }
}

.astrick {
  margin-top: -20px;
  border-radius: 50%;
  padding: 0px;
}

.body-large-regular {
  &.body-large-24 {
    font-size: rem(24);

    @include media-breakpoint-down(sm) {
      font-size: rem(20);
    }
  }
}

.body-small-regular {
  &.font-14 {
    font-size: rem(14);

    @include media-breakpoint-down(sm) {
      font-size: rem(12);
    }
  }
}

.sim-activation-sec {
  .back-button {
    margin-top: rem(48);

    @include media-breakpoint-down(sm) {
      margin-top: rem(24);
    }
  }
  .tariff-image {
    top: -30px;
    position: absolute;
    left: 0;
    right: 0;
  }
}

.tarif-overview-text {
  p {
    margin: 0px;
  }
}
