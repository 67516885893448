@import '~bootstrap/scss/mixins/breakpoints';
@import '../../../../../theme/function.scss';
@import '../../../../../theme/variables.scss';
@import '../../../../../theme/mixins.scss';
@import '../../../../../theme/typography.scss';

.terms-1 {
  font-size: 20px !important;
  @include media-breakpoint-down(sm) {
    font-size: 16px !important;
  }
}