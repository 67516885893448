@import '../../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../../../theme/function.scss';
@import '../../../theme/variables.scss';

.sim-activation-sec {
  // background-image: url('~/public/assets/images/sim-activation-btm-mobile-bg.svg');
  // background-position: center bottom;
  // background-repeat: no-repeat;
  // background-size: 100%;

  // @include media-breakpoint-up(sm) {
  //   background-image: url('~/public/assets/images/sim-activation-btm-bg.svg');
  // }

  // .orange-gray-bubble {
  //   &:before {
  //     top: rem(200);
  //   }
  // }

  .personal-head {
    line-height: 113%;
  }

  .font-24 {
    font-size: rem(24);

    @include media-breakpoint-down(sm) {
      font-size: rem(20);
    }
  }

  .font-16 {
    font-size: rem(16);
  }

  .font-20 {
    font-size: rem(20);
  }
}

.break-word {
  word-break: break-all;
  inline-size: 100% !important;
}

.radio-footnote {
  .promotional-text {
    p {
      margin-bottom: 0px;
    }
  }
	.astric {
		margin-top: -10px !important;
    border-radius: 50%;
    padding: 0px;
	}
}

.radio-description {
  p {
    margin-bottom: 0px;
  }
}
