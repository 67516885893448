@import '~bootstrap/scss/mixins/breakpoints';
@import '../../../../theme/function.scss';
@import '../../../../theme/variables.scss';
@import '../../../../theme/mixins.scss';
@import '../../../../theme/typography.scss';

.frequently-asked-section {
  .btn {
    @include media-breakpoint-down(sm) {
      width: 100% !important;
    }
  }
}

.share-social-QA-section {
  &.gray-shade-1 {
    &:after {
      background: url('~/public/assets/images/gray-curve-shade-bottom.svg') no-repeat;
      background-size: 100%;
      height: rem(140);
      bottom: rem(-136);
      @include media-breakpoint-down(lg) {
        height: rem(60);
        bottom: rem(-56);
      }
    }
  }
  .btn {
    @include media-breakpoint-down(sm) {
      width: 100% !important;
    }
  }
}
