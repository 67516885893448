// @import '@theme/variables.scss';
@import "~bootstrap/scss/mixins/breakpoints";
@import '../../../../theme/function.scss';
@import '../../../../theme/variables.scss';
@import '../../../../theme/mixins.scss';
@import '../../../../theme/typography.scss';
@import '../../../../theme/theme.scss';


.overlay {
	position: relative;
	overflow: hidden;

	&::after {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		content: "";
		background: rgba(38, 29, 46, 0.651);
		bottom: 0;
		z-index: 2;
		overflow: hidden;
	}
}

.navbar {
	// transition: all 0.3s ease-in-out;
	// -webkit-transition: all 0.3s ease-in-out;
	// -ms-transition: all 0.3s ease-in-out;
	// -moz-transition: all 0.3s ease-in-out;
	.navbar-brand {
		.logo {
			width: rem(213);
			@include media-breakpoint-down(sm) {
				width: rem(150);
			}
		}
	}

	.navbar-nav {
		transition: all 0.3s ease-in-out;
		-webkit-transition: all 0.3s ease-in-out;
		-ms-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		.nav-item {
			a {
				&.text-white {
					&:hover {
						font-family: $font-family-notosans-bold;
						color: $white !important;
						transition: all 0.3s ease-in-out;
						-webkit-transition: all 0.3s ease-in-out;
						-ms-transition: all 0.3s ease-in-out;
						-moz-transition: all 0.3s ease-in-out;
						background-color: transparent;
					}
					&:focus {
						color: $white !important;
						font-family: $font-family-notosans-bold;
						transition: all 0.3s ease-in-out;
						-webkit-transition: all 0.3s ease-in-out;
						-ms-transition: all 0.3s ease-in-out;
						-moz-transition: all 0.3s ease-in-out;
						background-color: transparent;
					}
				}
				&.primary-color-text {
					&:hover {
						font-family: $font-family-notosans-bold;
						color: $primary !important;
						transition: all 0.3s ease-in-out;
						-webkit-transition: all 0.3s ease-in-out;
						-ms-transition: all 0.3s ease-in-out;
						-moz-transition: all 0.3s ease-in-out;
						background-color: transparent;
					}
					&:focus {
						color: $primary !important;
						font-family: $font-family-notosans-bold;
						transition: all 0.3s ease-in-out;
						-webkit-transition: all 0.3s ease-in-out;
						-ms-transition: all 0.3s ease-in-out;
						-moz-transition: all 0.3s ease-in-out;
						background-color: transparent;
					}
				}
				
			}

			.dropdown-menu {
				border: none;
				background: transparent;
				transition: all 0.3s ease-in-out;
				-webkit-transition: all 0.3s ease-in-out;
				-ms-transition: all 0.3s ease-in-out;
				-moz-transition: all 0.3s ease-in-out;
				@include media-breakpoint-down(lg) {
					display: block;
				}

				&:before {
					background: url("~/public/assets/images/menu-shade.svg") no-repeat;
					background-size: cover;
					position: fixed;
					top: rem(81);
					bottom: 0;
					content: "";
					width: 100%;
					left: 0;
					height: rem(400);

					@include media-breakpoint-down(lg) {
						display: none;
					}
				}

				.inner-submenu {
					z-index: 1;
					position: relative;
					background: transparent;
					padding: 0;
					list-style-type: none;

					li {
						h6 {
							color: $pink-text ;
						}

						.dropdown-menu-title {
							font-family: "Patron-Regular";
							font-size: 1rem;
							line-height: 113%;
							letter-spacing: 1px;
							color: $pink-text;
							margin-bottom: 8px;
						}

						.dropdown-item {
							&:hover {
								background: transparent;
							}
						}
					}
				}
			}
		}
	}
}
