@import '~bootstrap/scss/functions', '~bootstrap/scss/variables';
@import '../../../../../node_modules/bootstrap/scss/mixins/breakpoints';

@import '../../../../theme/theme.scss';

.activation-order-section {
  &.pb-20 {
    @include media-breakpoint-down(xl) {
      padding-bottom: rem(90) !important;
    }

    @include media-breakpoint-down(sm) {
      padding-bottom: rem(40) !important;
    }
  }

  .w-50 {
    @include media-breakpoint-down(md) {
      width: 75% !important;
    }

    @include media-breakpoint-down(sm) {
      width: 100% !important;
    }
  }

  .legitimation-head {
    margin-left: 8.8%;

    @include media-breakpoint-down(sm) {
      margin-left: 1%;
    }

    .pink-color-text {
      margin-top: rem(137) !important;

      @include media-breakpoint-down(xxl) {
        margin-top: rem(137) !important;
      }

      @include media-breakpoint-down(xl) {
        margin-top: rem(137) !important;
      }

      @include media-breakpoint-down(lg) {
        margin-top: rem(137) !important;
      }

      @include media-breakpoint-down(md) {
        margin-top: rem(56) !important;
      }
      @include media-breakpoint-down(sm) {
        margin-top: rem(56) !important;
      }
    }

    .legitimation-text-wrapper {
      padding-top: rem(137) !important;
      @include media-breakpoint-down(md) {
        padding-top: rem(56) !important;
      }
    }
    .pink-text-wrapper {
      margin-top: 0 !important;
    }
  }

  .orange-gray-bubble {
    &:before {
      position: absolute;
      content: "";
      width: 100%;
      left: -5.875rem;
      height: 7.875rem;
      top: rem(290);
    }
  }
}

.legitimation-section {
  &.gray-shade-3 {
    &:before {
      @include media-breakpoint-down(sm) {
        background: url('~/public/assets/images/dot-shape-mobile.svg') no-repeat;
        top: rem(-9);
        background-size: cover;
        height: 5.2rem;

      }
    }
  }

  &.gray-shade-1 {
    &:after {
      @include media-breakpoint-down(sm) {
        background: url('~/public/assets/images/gray-dot-shape-mobile.svg') no-repeat;
        bottom: 0;
        height: 5.5rem;
        background-size: cover;
      }
    }
  }

  .orange-yellow-bubble {
    &:before {
      left: -5rem;
      top: 4.5rem;
    }
  }

  .sec-heading{
    @include media-breakpoint-down(sm) {
      margin-top: rem(32) !important;
    }
  }
}