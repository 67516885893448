// @import '@theme/variables.scss';
@import "~bootstrap/scss/mixins/breakpoints";
@import '../../../theme/function.scss';
@import '../../../theme/variables.scss';
@import '../../../theme/mixins.scss';
@import '../../../theme/typography.scss';

.white-shade-1:after {
	background: url("~/public/assets/images/white-curve-shade-1.svg") no-repeat;
	background-size: cover;
	position: absolute;
	bottom:  rem(120);
	content: "";
	width: 100%;
	left: 0;
	height: 120px;
}
.gray-shade-1:after {
	background: url("~/public/assets/images/gray-curve-shade-1.svg") no-repeat;
	background-size: cover;
	position: absolute;
	bottom: 0;
	content: "";
	width: 100%;
	left: 0;
	height: 120px;
	@include media-breakpoint-down(sm) {
		height: rem(60);
	}
}
.gray-shade-2:after {
	background: url("~/public/assets/images/gray-curve-shade-2.svg") no-repeat;
	background-size: cover;
	position: absolute;
	bottom: 0;
	content: "";
	width: 100%;
	left: 0;
	height: 120px;
	@include media-breakpoint-down(sm) {
		background: url("~/public/assets/images/gray-curve-shade-2-m.svg") no-repeat;
		background-size: 100%;
		height: rem(60);
	}
}
