@import "../../../../../node_modules/bootstrap/scss/mixins/breakpoints";
@import '../../../../theme/function.scss';
@import "../../../../theme/variables.scss";

.personal-row-1 {
    margin-bottom: 15px;
}

.personal-row-4 {
    .dob-div {
        margin-top: rem(32);

        @include media-breakpoint-up(xxl) {
            margin-top: rem(16);
        }

        @include media-breakpoint-up(xl) {
            margin-top: rem(16);
        }

        @include media-breakpoint-up(lg) {
            margin-top: rem(16);
        }

        @include media-breakpoint-up(md) {
            margin-top: rem(16);
        }

        @include media-breakpoint-up(sm) {
            margin-top: rem(16);
        }
    }
}

.pd-submit {
    margin-top: rem(48);

    @include media-breakpoint-up(xxl) {
        margin-top: rem(48);
    }

    @include media-breakpoint-up(xl) {
        margin-top: rem(48);
    }

    @include media-breakpoint-up(lg) {
        margin-top: rem(48);
    }

    @include media-breakpoint-up(md) {
        margin-top: rem(48);
    }

    @include media-breakpoint-up(sm) {
        margin-top: rem(40);
    }
}