@import "~bootstrap/scss/mixins/breakpoints";
@import '../../../theme/function.scss';
@import '../../../theme/variables.scss';
@import '../../../theme/mixins.scss';
@import '../../../theme/typography.scss';


//For friends page css start//

// .friends-purple-shade-1:after {
// 	background: url("~/public/assets/images/friends-purple-curve-shade-2.svg") no-repeat;
// 	background-size: cover;
// 	position: absolute;
// 	bottom: rem(-34);
// 	content: "";
// 	width: 100%;
// 	left: 0;
// 	height: rem(158);
// 	z-index: 1;
//     // transform: rotate(-180deg);
// 	@include media-breakpoint-down(xl) {
// 		bottom: rem(-69);
// 		height: rem(105);
// 	}

// 	@include media-breakpoint-down(lg) {
// 		bottom: rem(-48);
// 		height: rem(64);
// 	}
// 	@include media-breakpoint-down(sm) {
// 		height: rem(90);
// 		bottom: rem(-30);
// 		background: url("~/public/assets/images/friends-purple-curve-shade-2-mobile.svg") no-repeat;
// 		background-size: 100%;
// 	}
// }
// .friends-purple-shade-2:before {
// 	background: url("~/public/assets/images/friends-purple-curve-shade-1.svg") no-repeat;
// 	background-size: cover;
// 	position: absolute;
// 	top: rem(-156);
// 	content: "";
// 	width: 100%;
// 	left: 0;
// 	height: rem(160);
// 	@include media-breakpoint-down(xl) {
// 		top: rem(-84);
// 	}
// 	@include media-breakpoint-down(lg) {
// 		top: rem(-62);
// 		height: 5rem;
// 	}
// 	@include media-breakpoint-down(sm) {
// 		background: url("~/public/assets/images/friends-purple-curve-shade-1-mobile.svg") no-repeat;
// 		background-size: 100%;
// 		height: rem(150);
// 		top: rem(-85);
// 	}
// }

// .friends-purple-shade-3:after {
// 	background: url("~/public/assets/images/friends-purple-curve-shade-3.svg") no-repeat;
// 	background-size: cover;
// 	position: absolute;
// 	bottom: rem(-40);
// 	content: "";
// 	width: 100%;
// 	left: 0;
// 	height: rem(158);
// 	z-index: 1;
//     // transform: rotate(-180deg);
// 	@include media-breakpoint-down(xl) {
// 		bottom: rem(-117);
// 	}

// 	@include media-breakpoint-down(lg) {
// 		bottom: rem(-60);
// 		height: rem(83);
// 	}
// 	@include media-breakpoint-down(sm) {
// 		height: rem(60);
// 		bottom: -30px;
// 	}
// }

.share-mobile-app-row {
	&.friends-purple-shade-2 {
		&::before {
			@include media-breakpoint-down(sm) {
				background: url("~/public/assets/images/friends-purple-curve-shade-4-mobile.svg") no-repeat;
				background-size: 100%;
				height: rem(100);
				top: rem(-77);
			}
		}
	}
}

.digital-education-img {
	position: absolute;
	top: 0;
	right: 0;
	@include media-breakpoint-down(xl) {
		padding: 0;
	}
	@include media-breakpoint-down(lg) {
		position: relative;
		margin: 35px auto 0;
	}
	@include media-breakpoint-down(sm) {
		text-align: right;
		padding: 0 !important;
	}

}

.mobile-width-100 {
	@include media-breakpoint-down(xl) {
		width: 100% !important;
	}
	@include media-breakpoint-down(lg) {
		width: 100% !important;
	}
	@include media-breakpoint-down(sm) {
		width: 65% !important;
	}

}


.digital-edu-row {
	.pt-10 {
		@include media-breakpoint-down(xl) {
			padding-top: 30px !important;
		}
		@include media-breakpoint-down(lg) {
			padding-top: 30px !important;
		}
	}
	@include media-breakpoint-down(sm) {
		padding-bottom: 30px;
	}

}
.how-it-done-row {
	&.pt-10 {
		@include media-breakpoint-down(xl) {
			padding-top: 30px !important;
		}
		@include media-breakpoint-down(lg) {
			padding-top: 30px !important;
		}
	}
	.ps-5 {
		@include media-breakpoint-down(sm) {
			padding-left: 15px !important;
		}
	}
}

.share-mobile-app-row {
	&.pt-10 {
		@include media-breakpoint-down(xl) {
			padding-top: 30px !important;
		}
		@include media-breakpoint-down(lg) {
			padding-top: 30px !important;
		}
		@include media-breakpoint-down(sm) {
			padding-top: 70px !important;
		}
	}
	.pb-20 {
		@include media-breakpoint-down(xl) {
			padding-bottom: 30px !important;
		}
		@include media-breakpoint-down(lg) {
			padding-bottom: 30px !important;
		}
	}
}

.get-more-gb-row {
	.mobile-100 {
		@include media-breakpoint-down(sm) {
			width: 100% !important;
			margin: 0 0 60px !important;
		}
	}
}

.how-it-done-row {
	.w-50 {
		@include media-breakpoint-down(sm) {
			width: 100% !important;
			margin: 0 0 30px !important;

			&:last-child {
				margin-bottom: 110px !important;
			}
		}
	}
}

.question-row {
	&.my-5 {
		@include media-breakpoint-down(sm) {
			margin-top: 0 !important;
			padding-top: 50px !important;
		}
	}
	.mt-5 {
		@include media-breakpoint-down(sm) {
			margin-top: 0 !important;
		}
	}
}
//For friends page css start//



